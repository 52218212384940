.image-upload {
  /* background-color: aqua; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.image-upload .image-container {
  /* background-color: antiquewhite; */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image-upload img {
  max-width: 35vh;
  max-height: 35vh;
  clip-path: polygon(
    10% 0, /* top left corner */
    90% 0, /* top right corner */
    100% 10%, /* right top corner */
    100% 90%, /* right bottom corner */
    90% 100%, /* bottom right corner */
    10% 100%, /* bottom left corner */
    0 90%, /* left bottom corner */
    0 10% /* left top corner */
  );
  object-fit: contain;
  width: auto;
  height: auto;
}