.loading {
  background-image: url('~/public/assets/page4/loading_bg.jpg');
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: top;
  flex-direction: column;
}

.gif-container {
  margin-top: 10vh;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gif-container .gif {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding-top: 1vh;
}

.dots {
  margin-top: 10vh;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dots .dot {
  background-color: #bbbbbb;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin: 0 5px;

  /* Animation */
  animation: bounce 1s infinite ease-in-out;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }
}