/* Page1.css */

body {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

h1 {
  margin: 0;
  padding: 0;
}

.page {
  height: 100vh;
}

.page1 {
  /* background-image: linear-gradient(to bottom, #FFA07A, #FFD700); */
  background-image: url('~/public/assets/page1/page1_bg.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page1 .link-container {
  width: 100vw;
  margin-top: 85vh;
  display: flex;
  justify-content: center;
}

.page1 .next-btn {
  width: 46vw;
  animation: fadeIn 4s ease-in-out forwards;
  animation-delay: 0s;
  opacity: 0;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}