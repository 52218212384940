body {
  overflow: hidden;
}

h1 {
  margin: 0;
  padding: 0;
}

.page {
  height: 100vh;
}

.page1 {
  background: rgb(99, 26, 41);
}

.page2 {
  background: rgb(99, 26, 41);
}

.page3 {
  background: rgb(99, 26, 41);
}

#rotateDevice {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  font-size: 2em;
  text-align: center;
  padding-top: 20vh;
}