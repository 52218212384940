.hint {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: top;
  flex-direction: column;
}

.hint-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: top;
}

.hint-container .bg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}