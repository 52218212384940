body {
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
}

.video-show-page {
  background-color: rgb(99, 26, 41);
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  /* height: 100vh; */
  pointer-events: none;
  overflow: visible;
}

.bg-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  /* background-color: rosybrown; */
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.bg-wrapper .bg {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  pointer-events: none;
  z-index: 1;
}

.video-container {
  position: relative;
  background-color: rgb(99, 26, 41);
  height: 80vh;
  width: 80vw;
  max-width: 80vw;
  max-height: 80vh;
  margin-top: 5vh;
  z-index: 0;
  pointer-events: auto;
}

.video-container .video {
  height: 100%;
  width: 100%;
  position: relative;
  max-width: 80vw;
  max-height: 80vh;
  object-fit: cover;
  z-index: 0;
}
.video-container .video-d {
  height: 100%;
  width: 100%;
  position: relative;
  max-width: 80vw;
  max-height: 70vh;
  object-fit: fill;
  z-index: 0;
}

.video-show-page .download-container {
  width: 100vw;
  margin-top: 0vh;
  display: flex;
  justify-content: center;
  z-index: 2;
}

.video-show-page .download-container .download-btn {
  width: 55vw;
  animation: fadeIn 2s ease-in-out forwards;
  animation-delay: 0s;
  opacity: 0;
  pointer-events: auto;
}

.video-show-page .download-container .download-btn-out {
  margin-top: -3vh;
  width: 55vw;
  animation: fadeIn 2s ease-in-out forwards;
  animation-delay: 0s;
  opacity: 0;
  pointer-events: auto;
}

.PS_ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: top;
  z-index: 3;
}

.PS_ReactModal__Content {
  position: relative;
  background: #ffffff02;
  padding: 1vw;
  border-radius: 1vw;
  width: 90vw;
  height: 90vh;
  max-width: 500px;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: top;
}

@media (max-width: 600px) {
  .PS_ReactModal__Content {
    border-radius: 1vw;
    width: 90vw;
    height: 90vh;
    max-width: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: top;
  }
}

.PS_ReactModal__Overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: top;
  z-index: 3;
  transition: opacity 0.2s ease-in-out;
  z-index: 3;
}

.PS_ReactModal__Content2 {
  position: relative;
  background: #fff;
  padding: 1vw;
  border-radius: 1vw;
  width: 90vw;
  height: 30vh;
  max-width: 500px;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: top;
}

.PS_ReactModal__Content2 h2 {
  text-align: center;
  margin-top: 0;
  color: #333;
  font-size: 4vh;
}

.PS_ReactModal__Content2 p {
  text-align: center;
  color: #666;
  line-height: 1.6;
  font-size: 2.5vh;
}

.PS_ReactModal__Content2 button {
  background: #fff;
  color: rgb(99, 26, 41);
  border: none;
  border-radius: 5px;
  padding: 1vh 2vw;
  cursor: pointer;
  margin-top: 2vh;
  font-size: 2.5vh;
  transition: background 0.2s ease-in-out;
}

@media (max-width: 600px) {
  .PS_ReactModal__Content2 {
    margin-top: 2vh;
    width: 90%;
    height: 25%;
    max-width: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: top;
  }

  .PS_ReactModal__Content2 h2 {
    margin-top: 2vh;
    text-align: center;
    color: #222;
    font-size: 4vh;
  }

  .PS_ReactModal__Content2 p {
    margin: 1vh 0;
    color: #444;
    text-align: center;
    font-size: 2.5vh;
  }

  .PS_ReactModal__Content2 button {
    background: #fff;
    color: rgb(99, 26, 41);
    border: none;
    border-radius: 5px;
    padding: 1vh 2vw;
    cursor: pointer;
    margin-top: 2vh;
    font-size: 2.5vh;
    transition: background 0.2s ease-in-out;
  }
}