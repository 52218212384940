body {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

h1 {
  margin: 0;
  padding: 0;
}

.page {
  height: 100vh;
}

.page2 {
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  height: 100vh;
}

.upload-image {
  margin-top: 30vh;
  /* background-color: bisque; */
  width: 70vw;
  height: 70vw;
  display: flex;
  justify-content: center;
}

.page2 .link-container {
  width: 100vw;
  margin-top: 19vh;
  display: flex;
  justify-content: center;
}

.page2 .link-container .next-btn {
  width: 60vw;
  animation: fadeIn 2s ease-in-out forwards;
  animation-delay: 0s;
  opacity: 0;
}