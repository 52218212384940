/* page4.css */
body {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.page4 {
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  height: 100vh;
}

.page4 h1 {
  margin-bottom: 10px;
}

.page4 .image-container {
  /* background-color: aquamarine; */
  margin-top: 30vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2vw;
  /* max-height: 100%; */
  max-width: 100%;
  height: 53vh;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.page4 .image-container img {
  width: 100%;
  height: 45vh;
  object-fit: contain;
  padding-top: 1vh;
}

.page4 .submit-container {
  width: 100vw;
  margin-top: 1vh;
  display: flex;
  justify-content: center;
}

.page4 .submit-container .submit-btn {
  width: 55vw;
  animation: fadeIn 2s ease-in-out forwards;
  animation-delay: 0s;
  opacity: 0;
}

.P4_ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.P4_ReactModal__Content {
  position: relative;
  background: #fff;
  padding: 1vw;
  border-radius: 1vw;
  width: 50vw;
  height: 50vh;
  max-width: 500px;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  .P4_ReactModal__Content {
    width: 90%;
    height: 60%;
    max-width: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.P4_ReactModal__Content h2 {
  margin-bottom: 60px;
}