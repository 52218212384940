/* Page3.css */
body {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.page3 {
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  height: 100vh;
}

.page3 h1 {
  margin-bottom: 10px;
}

.page3 .image-container {
  /* background-color: aqua; */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 30vh;
  gap: 2vw;
  /* max-height: 100%; */
  /* max-width: 90%; */
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.page3 .image-container img {
  width: 100%;
  height: 45vh;
  object-fit: contain;
}

.page3 .link-container {
  /* background-color: aqua; */
  width: 100vw;
  margin-top: 6vh;
  display: flex;
  justify-content: center;
}

.page3 .link-container .next-btn {
  width: 60vw;
  animation: fadeIn 2s ease-in-out forwards;
  animation-delay: 0s;
  opacity: 0;
}



